<template>
  <div class="groupProgress">
    <div class="header-box">
      <span v-if="!ifProcessMonitor" class="golink" @click="linkTo()">
        <i class="el-icon-arrow-left"></i>返回上一级</span
      >
      <refreshTime ref="refreshTime"></refreshTime>
    </div>
    <div v-loading="listLoading" class="search-box">
      分组：
      <el-select
        v-model="search.groupId"
        placeholder=""
        filterable
        @change="getNewView()"
      >
        <el-option
          v-for="item in groupList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div v-for="(v, i) in rateListData" :key="i" class="mark-paper-list-box">
      <div class="mark-paper-list-header">
        <span class="paper-title">
          {{ v.subjectName }}

          <!-- <template v-if="v.rollingMethod == 1 && v.scoringMode == 1">
            （考生人数：{{ indexGroup.studentCount || 0 }}，正常卡人数：
            <span class="err">{{ indexGroup.normalAnwserSheet || 0 }}</span
            >）</template
          > -->
          <template>
            （考生人数：{{ indexGroup.studentCount }}，正常卡人数：
            <span class="err">{{ indexGroup.normalAnwserSheet }}</span
            >）
          </template>
        </span>
      </div>
      <div class="progress-box">
        <div class="progress-box-text">阅卷进度</div>
        <template v-if="!Number(indexGroup.rate)">
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="0"
          ></el-progress>
        </template>
        <template
          v-if="0 < Number(indexGroup.rate) && Number(indexGroup.rate) <= 1"
        >
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="Number((indexGroup.rate * 100).toFixed(2))"
          ></el-progress>
        </template>
        <template v-if="Number(indexGroup.rate) > 1">
          <el-progress
            :text-inside="true"
            :stroke-width="100"
            :percentage="100"
          ></el-progress>
        </template>
        <div class="progress-box-text">
          {{ indexGroup.rate | setNumber }}
        </div>
      </div>

      <div class="check-box">
        <!-- 学校：
        <el-select
          v-model="search.schoolId"
          placeholder=""
          filterable
          @change="getNewItem()"
        >
          <el-option label="全部学校" value=""> </el-option>
          <el-option
            v-for="item in schoolList"
            :key="item.schoolId"
            :label="item.schoolName"
            :value="item.schoolId"
          >
          </el-option>
        </el-select> -->
        阅卷状态：
        <el-select
          v-model="search.readStatus"
          placeholder=""
          @change="monitorRateDetail()"
        >
          <el-option label="全部" value=""> </el-option>
          <el-option
            v-for="item in readTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div v-loading="boxLoading" class="set-up-rules">
        <div v-if="getScoringMode() && showTips" class="tips-msg">
          <div>
            <i class="el-icon-warning"></i>
            点击列表内学校名称，可查看单校阅卷详情！
          </div>

          <i class="el-icon-close" @click="showTips = false"></i>
        </div>
        <questionTab ref="questionTab" :show-table-type="1"> </questionTab>
      </div>
    </div>
  </div>
</template>

<script>
import refreshTime from "../components/refreshTime";

import { showSubject } from "@/core/util/util";

import {
  monitorRateList,
  //   monitorRateDetail,
  qustionworkload,
  singleWorkload,
  unionGrouprate,
} from "@/core/api/exam/paperMonitor";
import { allschoollistbyexamid } from "@/core/api/exam/examTeacher";
import { schoolGroupList } from "@/core/api/exam/examCorrectTask";
import { readTypeList } from "@/core/util/constdata";
import questionTab from "./components/questionTab.vue";
import { getStore } from "@/core/util/store";
export default {
  name: "GroupProgress",
  components: { refreshTime, questionTab },
  data() {
    return {
      readTypeList: readTypeList,
      boxLoading: false,
      listLoading: false,
      rateListData: [],
      ifProcessMonitor: "",
      indexGroup: {},
      groupList: [],
      schoolList: [],
      showTips: true,
      // indexPaper: {},
      search: {
        groupId: "",
        readStatus: "",
        schoolId: "",
      },
    };
  },
  async created() {
    this.ifProcessMonitor = getStore({
      name: "ifProcessMonitor",
    });
    await this.getschoolGroupList();
    // this.getSchoollistbyexamid(1);
    this.init();
  },
  methods: {
    async getNewView() {
      let data = JSON.parse(JSON.stringify(this.$route.query));
      data.groupId = this.search.groupId;
      this.$router.push({
        query: data,
      });
      // this.getSchoollistbyexamid();
      await this.getunionGrouprate();
      this.monitorRateDetail();
    },
    async getSchoollistbyexamid(type) {
      this.listLoading = true;
      const res = await allschoollistbyexamid({
        examId: this.$route.query.examId,
        groupId: this.$route.query.groupId,
      });
      this.search.schoolId = "";
      // this.search.schoolId = Number(this.$route.query.schoolId);
      this.listLoading = false;
      this.schoolList = res.data.data;
      if (!type) {
        this.monitorRateDetail();
      }
    },
    getScoringMode() {
      if (!this.groupList || this.groupList.length == 0) return false;
      let scoringMode = this.groupList.filter(
        (item) => item.id == this.search.groupId
      )[0].scoringMode;

      return scoringMode == 1 ? true : false;
    },
    async getunionGrouprate() {
      let { examPaperId } = this.$route.query;
      let data = {
        examPaperId: examPaperId,
        groupId: this.search.groupId,
        rateSort: this.rateSort,
      };
      await unionGrouprate(data)
        .then((res) => {
          this.boxLoading = false;
          this.$nextTick(() => {
            this.indexGroup = res.data.data.records[0];
            if (this.indexPaper) {
              this.indexPaper.normalAnwserSheet =
                this.indexGroup.normalAnwserSheet;
              this.indexPaper.examStudent = this.indexGroup.examStudent;
            }
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    linkTo() {
      let data = JSON.parse(JSON.stringify(this.$route.query));
      delete data.groupId;
      delete data.fromUrl;
      this.$router.push({
        name: this.$route.query.fromUrl || "unionMarkingProgress",
        query: data,
      });
    },
    getNewItem() {
      let data = JSON.parse(JSON.stringify(this.$route.query));
      data.groupId = this.search.groupId;
      this.$router.push({
        query: data,
      });
      this.monitorRateDetail();
      this.getindexGroup();
    },

    //联考-获取学校分组列表
    async getschoolGroupList() {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.examPaperId,
      };
      await schoolGroupList(data)
        .then((res) => {
          this.groupList = res.data.data;
          if (this.$route.query.groupId) {
            this.search.groupId = Number(this.$route.query.groupId);
          } else {
            this.search.groupId = res.data.data[0].id;
            let data = JSON.parse(JSON.stringify(this.$route.query));
            data.groupId = this.search.groupId;
            this.$router.push({
              query: data,
            });
          }

          this.getunionGrouprate();
        })
        .catch(() => {});
    },
    getindexGroup() {
      this.getunionGrouprate();
    },
    showSubject(v) {
      return showSubject(v, 9);
    },
    async init() {
      let { examPaperId } = this.$route.query;

      this.examPaperId = examPaperId;
      // await this.getSchoollistbyexamid(schoolId);

      this.monitorRateList();
    },
    // 获取单科阅卷详情
    monitorRateDetail() {
      let { examPaperId } = this.$route.query;
      let data = {
        examPaperId: examPaperId,
        // groupId: groupId,
      };
      Object.assign(data, this.search);
      this.boxLoading = true;
      singleWorkload(data)
        .then((res) => {
          this.boxLoading = false;
          this.tableData = res.data.data;

          this.$nextTick(() => {
            this.$refs.questionTab[0].init(this.indexPaper, this.tableData);
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    // 获取单科阅卷详情
    qustionworkload() {
      let data = {
        examPaperId: this.examPaperId,
      };
      Object.assign(data, this.search);
      this.boxLoading = true;
      qustionworkload(data)
        .then((res) => {
          this.boxLoading = false;
          this.tableData = res.data.data;

          this.$nextTick(() => {
            this.$refs.questionTab[0].init(this.indexPaper, this.tableData);
          });
        })
        .catch(() => {
          this.boxLoading = false;
        });
    },
    monitorRateList() {
      let data = {
        examId: this.$route.query.examId,
      };
      Object.assign(data, this.search);
      monitorRateList(data).then((res) => {
        let array = res.data.data.filter(
          (item) => item.examPaperId == this.examPaperId
        );
        array[0].normalAnwserSheet = this.indexGroup.normalAnwserSheet;
        array[0].examStudent = this.indexGroup.examStudent;
        this.rateListData = array;
        this.indexPaper = this.rateListData[0];
        this.monitorRateDetail(this.indexPaper);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.groupProgress {
  padding: 18px 18px 0;
  .tips-msg {
    background: #f3f8ff;
    border-radius: 4px;
    padding: 10px 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #262829;
    margin-bottom: 12px;
    .el-icon-close {
      cursor: pointer;
    }
    .el-icon-warning {
      color: #2474ed;
    }
  }
  .header-box {
    margin-bottom: 18px;
  }
  .search-box {
    margin-bottom: 18px;
  }
  ::v-deep {
    .el-progress-bar__inner {
      border-radius: 0;
    }
  }
  .golink {
    color: #2474ed;
    margin-right: 18px;
    cursor: pointer;
  }
  .student-box {
    .el-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }
    .model-search-box {
      margin-bottom: 24px;
      .el-input,
      .el-select {
        width: 180px;
      }
      .el-select {
        margin-right: 24px;
      }
      .el-input {
        margin-right: 24px;
      }
    }
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #ededef;
    }
  }

  .mark-paper-list-box {
    margin-bottom: 18px;
    border: 1px solid #e4e4e4;

    ::v-deep .set-up-rules {
      .el-table__header {
        th {
          padding: 18px 0;
        }
      }
      .cell {
        padding: 0;
      }
    }

    .el-progress {
      height: 12px;
      line-height: 11px;
      width: calc(100% - 180px);
      ::v-deep .el-progress-bar__outer {
        height: 12px !important;
        border-radius: 0;
        .el-progress-bar__innerText {
          display: none;
        }
      }
    }

    .search-box {
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        align-items: center;
      }
      .el-button {
        float: right;
      }
      .check-item-box {
        display: flex;
        align-items: center;
        div {
          padding: 9px 8px;
          margin-right: 18px;
          border-radius: 4px;
          background: #c3c7cc;
          color: #ffffff;
          cursor: pointer;
        }
        .on {
          &:nth-child(1) {
            background: #fc8586;
          }
          &:nth-child(2) {
            background: #2474ed;
          }
          &:nth-child(3) {
            background: #04c391;
          }
        }
      }
      .el-select {
        margin-right: 32px;
      }
    }
    .set-up-rules {
      padding: 18px;
    }
    .check-box {
      background: #f9fafc;
      border-top: 1px solid #dee0e7;
      border-bottom: 1px solid #dee0e7;
      padding: 6px 18px;
      .el-select {
        margin-right: 18px;
        width: 180px;
      }
    }
    .progress-box {
      padding: 0 18px 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progress-box-text {
        flex-shrink: 0;
      }
    }
    .mark-paper-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 18px;
      .paper-check {
        color: $primary-color;
        cursor: pointer;
        .el-icon-arrow-down {
          transition: 0.2s;
        }
        .show-i {
          transform: rotate(180deg);
          transition: 0.2s;
        }
      }
      .paper-title {
        position: relative;
        .err {
          color: #ff2b00;
        }
        // &::after {
        //   content: "";
        //   width: 4px;
        //   height: 12px;
        //   background: $primary-color;
        //   position: absolute;
        //   left: -8px;
        //   top: 4px;
        //   border-radius: 2px;
        // }
      }
    }
  }
}
</style>
